.pointer {
  cursor: pointer;
}
.bg-white {
  background-color: white;
}

.scroll-col {
  flex: 1;
  background: #343a40;
  overflow-y: scroll;
}
.button {
  border: 0;
  margin: 0;
  padding: 0;
}

.home-page-card-button {
  display: flex;
  justify-content: center;
  border: 0;
  margin: 0;
  padding: 0 !important;
}

.home-page-cards-container {
  background: #000;
  padding: 3rem;
  height: 100vh;
}
.card-component-container {
  background: #121213;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 5px;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.small_container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}
.dfc {
  display: flex;
  flex-direction: column;
}

.card {
  overflow-y: scroll;
  max-height: 80vh;
}

.title {
  text-align: start;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 54px;
  color: #000000;
}

.desc {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.artists-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.42px;
  color: #000000;
}

.card-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #f8f6f8;
}

.card-address {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.42px;
  color: #f8f6f8;
}

.text-white {
  color: white;
}
.bg-color {
  background-color: #000000 !important;
}
.bg-grey {
  background-color: #121213;
}

.bg-grey-600 {
  background-color: #191919;
}
@media (max-width: 768px) {
  .make-us-visible-img {
    height: 360px;
  }
}

.change-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.change-scroll::-webkit-scrollbar {
  width: 32px !important;
  background-color: transparent;
}

.change-scroll::-webkit-scrollbar-thumb {
  background-color: #e0f752;
  border: 14px solid #000;
  background-clip: padding-box;
  border-radius: 50px;
}

.full-image_container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 17;
}

@media (max-width: 850px) {
  .card-component-container {
    width: 100%;
  }
  .home-page-cards-container {
    height: max-content;
  }
}

.set-margin-top-for-cities-page {
  margin-top: 2.5rem !important;
}

@media (min-width: 750px) {
  .set-margin-top-for-cities-page {
    margin-top: 3rem !important;
  }
}

.sideBar-container {
  overflow: hidden;

  border-radius: 12px;
  background-color: black;
  padding: 1.5rem;
}

.sideBar-card {
  max-height: 185px;
  height: 100%;
  cursor: pointer;
  background-color: var(--white);
  border-radius: 12px;
  padding: 10px 10px;
  display: flex;
  column-gap: 3%;
  max-width: 600px;
}
.sideBar-card img {
  border-radius: 12px;
  min-height: 165px;
  max-height: 165px;
  min-width: 160px;
  max-width: 160px;
  object-fit: cover;
  background-color: var(--red100);
}

@media (max-width: 600px) {
  .sideBar-card {
    flex-wrap: wrap;
    max-height: 350px;
    height: 100%;
  }
  .sideBar-card img {
    flex: 1;
  }
}

.mt-9rem {
  margin-top: 9rem;
}

@media (max-width: 768px) {
  .mt-9rem {
    margin-top: 11vh;
  }
}

.mt-4rem {
  margin-top: 4rem;
}

@media (max-width: 768px) {
  .mt-4rem {
    margin-top: 4vh;
  }
}
