@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');


html {
  position: relative;
  background-color: #121213;
  font-family: "Inter", sans-serif;

}


body::-webkit-scrollbar-track {
  background-color: transparent !important;
  
}
body{
  background-color: black !important;
}
      
body::-webkit-scrollbar {
  width: 0.5px !important;
  background-color: transparent!important;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}

@media (max-width: 768px) {
  body {
    overflow: scroll;
    background-color: black;
    
  }
}

iframe{
  display: none;
}
.ReactModal__Body--open{
  overflow: hidden;
}