.bottom-container {
  position: absolute;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  color: aliceblue;
  scroll-snap-type: y mandatory;
  background-color: transparent;
}
.bottom-sheet-top {
  padding: 20px;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  scroll-snap-align: end;
}
.bottom-sheet {
  box-shadow: 0px -1px 10px #606060;
  padding: 12px;
  width: 100vw;
  height: 87vh;
  background-color: black;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  scroll-snap-align: center;
}
.bg-lime {
  margin: 6px;
  background: #e0f752;
  width: 60px;
  height: 2px;
  border-radius: 20px;
}
.top-sheet{
display: flex;
flex-direction: column;
align-items: center;

}
.h-20 {
  height: 30px;
}
.scroll {
  overflow: scroll;
}
.scroll::-webkit-scrollbar {
  display: none;
}
.sheet-content-container {
  width: 100%;
  height: 75vh;
  background-color: black;
}
@media (min-width: 767px) {
    .bottom-container{
        display: none;
    }
}
.swipe-down{
    margin-bottom: 10px;
    font-size: 14px;
    color: black;
    animation-name: swipe;
    animation-duration: 4s;
    animation-iteration-count: 2;
}

@keyframes swipe{
    0% {
      color: black;
    }
    25% {
        color: #808080;
    }
    50% {
        color: black;
    }
    75% {
        color: #808080;
    }
    100% {
        color: black;
    }
  }

  .react-modal-sheet-content{
    background: black;
  }
  