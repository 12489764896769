.mint-btn{
  height:52px;
  width: 180px;
  font-weight: 500;
  padding: auto;
  border:none;
  border-radius: 4px;
  background-color: #e0f752;
}
.toast-header{
  justify-content: space-between;
}

.font-color{
  color: rgb(255,255,255,0.7);

}