.navbar-user-icon {
  color: #ababab;
  background-color: #ababab66;
  border-radius: 50%;
  width: 36px;
  padding: 4px;
}

.notification-group {
  position: relative;
}
.notification-dot {
  position: absolute;
  right: 2px;
  top: 2px;
  background-color: red;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.navbar-user-icon:hover {
  color: white;
}

.navbar-logout-icon {
  width: 20px;
}
.navbar-dropdown-custom .dropdown-toggle::after {
  content: none;
}

@media (max-width: 460px) {
  .custom-navbar-for-create-event-screen .bg-dark {
    background-color: #000 !important;
  box-shadow: 0px 0px 4px 1px var(--lime);
  }
}

@media (min-width : 460px){
  .custom-navbar-for-create-event-screen .bg-dark {
    background-color: #000 !important;
  }
}

.custom-navbar .bg-dark {
  background-color: #000 !important;
}

.custom-navbar .dropdown-menu {
  right: 0 !important;
  left: unset !important;
}

.nav-height {
  padding: 1rem !important;
}
