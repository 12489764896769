.mainNRVLD {
    display: flex;
    justify-content: center;
    background-color: #787878;
}

.NRVLDImg {
    height: 100vh;
}

@media (max-width: 768px) {
    .NRVLDImg {
        height: 60vh;
        width: 100%;
    }
}

