.h-screen {
  height: 100vh;
}

.max-h-screen {
  max-height: 100vh;
}

.h-90vh {
  height: 90vh;
}

.overflow-y-scroll {
  overflow-y: scroll;
}
.br {
  border-right: 2px solid #e0f752;
}

.overflow-y-scroll::-webkit-scrollbar {
  display: none;
}

.z-20 {
  z-index: 20;
}
/* 
iframe:last-child {
  display: none;
} */
.lg-hidden {
  display: none;
}
@media (max-width: 767px) {
  .lg-hidden {
    display: block;
  }
  .sm-hidden {
    display: none;
  }
}
.bg-parrot {
  background-color: #e0f752;
}
.text-parrot {
  color: #e0f752;
}
iframe {
  display: none;
}
