@font-face {
  font-family: 'SF-Compact-Black';
  src: url('./assets/fonts/SF_Compact_Display/SF-Compact-Display-Black.woff2');
}

@font-face {
  font-family: 'SF-Compact-Medium';
  src: url('./assets/fonts/SF_Compact_Display/SF-Compact-Display-Medium.woff2');
}
@font-face {
  font-family: 'SF-Compact-Regular';
  src: url('./assets/fonts/SF_Compact_Display/SF-Compact-Display-Regular.woff2');
}
@font-face {
  font-family: 'SF-Compact-Light';
  src: url('./assets/fonts/SF_Compact_Display/SF-Compact-Display-Light.woff2');
}
@font-face {
  font-family: 'SF-Compact-Semibold';
  src: url('./assets/fonts/SF_Compact_Display/SF-Compact-Display-Semibold.woff2');
}

@font-face {
  font-family: 'SF-Compact-Bold';
  src: url('./assets/fonts/SF_Compact_Display/SF-Compact-Display-Bold.woff2');
}
@font-face {
  font-family: 'New-York-Medium';
  src: url('./assets/fonts/New_York/NewYorkExtraLargeMedium.otf');
}
@font-face {
  font-family: 'New-York-Extra-Large';
  src: url('./assets/fonts/New_York/NewYorkExtraLargeBlack.woff2');
}
@font-face {
  font-family: 'New-York-Regular';
  src: url('./assets/fonts/New_York/NewYorkExtraLargeRegular.otf');
}
@font-face {
  font-family: 'New-York-Semibold';
  src: url('./assets/fonts/New_York/NewYorkExtraLargeSemibold.otf');
}
li,
ul {
  font-family: 'SF-Compact-Regular', 'sans-serif';
}
body,
p,
label {
  font-family: 'SF-Compact-Medium', 'sans-serif';
  font-size: 16px;
}
em,
strong,
small {
  font-family: 'SF-Compact-Medium', 'sans-serif';
  font-size: 16px;
  opacity: 0.5;
  letter-spacing: 0.5px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'New-York-Medium', sans-serif;
}

/* NEW YORK FONT */
/* CDN LINK --- https://www.cdnfonts.com/new-york-extra-large.font */
.NY_Font--xl {
  font-family: 'New-York-Extra-Large', sans-serif;
}

.NY_Font--sb {
  font-family: 'New-York-Semibold', sans-serif;
}
.NY_Font--md {
  font-family: 'New-York-Medium', sans-serif;
}
.NY_Font--rg {
  font-family: 'New-York-Regular', sans-serif;
}

/* SF COMPACT DISPLAY FONT */
/* FONT LINK --- https://developers.apple.com */
.SCD_Font--black {
  font-family: 'SF-Compact-Black', 'sans-serif';
}

.SCD_Font--regular {
  font-family: 'SF-Compact-Regular', 'sans-serif';
}
.SCD_Font--semibold {
  font-family: 'SF-Compact-Semibold', 'sans-serif';
}
.SCD_Font--medium {
  font-family: 'SF-Compact-Medium', 'sans-serif';
}
.SCD_Font--bold {
  font-family: 'SF-Compact-Bold', 'sans-serif';
}
