.event-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 25px;
  color: #ffffff;
}

.event-desc-heading {
  font-style: normal;
  font-size: 20px;
  color: #ffffff;
}

.lime-btn-with-blue-text {
  background-color: #e0f752;
  color: #001aff;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  border-radius: 4px;
  text-align: center;
  padding: 12px 20px;
}

.lime-btn-with-blue-text:hover {
  background-color: #d9f044;
}


.event-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.32px;
  color: #ffffff;
  opacity: 0.5;
}

.login-btn {
  color: #ffffff;
  background: none;
  border: 1px solid #001aff;
  border-radius: 4px;
  padding: 8px 24px;
}

.login-btn:hover {
  background-color: #001aff;
  color: #fff;
}

@media (max-width: 380px) {
  .create-event-btn {
    margin: 0rem!important;
    padding: 0.5rem 0.5rem!important;
  }
}

@media (max-width: 768px) {
  .create-event-btn {
    margin: 0rem!important;
  }
}


.create-event-btn {
  font-size: 18px;
  color: #e0f752;
  background: none;
  border: 1px solid #c4c4c494;
  border-radius: 6px;
  margin: 0px 1rem;
  padding: 0.5rem 1.25rem;
}

.create-event-btn:hover {
  background-color: #e0f752;
  color: #001aff;
}

.artist-name {
  font-size: 17px;
  line-height: 20px;
  color: #fff;
}

.exhibition-name {
  font-style: normal;
  line-height: 25px;
  font-weight: bold;
  font-size: 20px;
}

.event-learn-more {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}

.hide-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.hide-scroll::-webkit-scrollbar {
  width: 3px !important;
  background-color: transparent;
}

.hide-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.artwork-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.12px;
  color: #ffffff;
}

.artwork-name {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
}

.view-website {
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: -0.12px;
  color: #FB5959;
  ;

}

.artists-location {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  opacity: 0.5;
  letter-spacing: -0.11px;
  color: #ffffff;
  margin-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: start;
}

.location-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.12px;
  color: #ffffff;
}

.react-modal-sheet-container {
  /* custom styles */
  background-color: #292929 !important;
}

.react-modal-sheet-drag-indicator {
  /* custom styles */
  background-color: #e0f752 !important;
  width: 30px !important;
}

.react-modal-sheet-backdrop {
  /* custom styles */
  overflow: hidden !important;
}

.react-modal-sheet-header {
  height: 70px !important;
  background-color: #000000;
}

.location {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  opacity: 0.8;
  color: #ffffff;
}

.event-date {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.32px;
  color: #ffffff;
  opacity: 0.5;
}

.button-rsvp {
  float: right;
  height: 30px;
  width: 113px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.image-container {
  height: 280px;
  width: 300px;
}

.image-container-2 {
  height: 165px;
  width: 100%;
  cursor: pointer;
  background-repeat: no-repeat;
}

.image-container-3 {
  height: 188px;
  width: 191px;
}

.object-fit-image {
  object-fit: cover;
}

.artist-img-container {
  height: 81px;
  width: 81px;
  cursor: pointer;
  border-radius: 50%;
}

.artwork-img-container {
  height: 100px;
  width: 100px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 12px;
}

.artwork-audio {
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.67);
  height: 55px;
  width: 55px;
  position: absolute;
  top: 23%;
  left: 23%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.event-card-date {
  color: #3948d1;
}
.event-card-artist {
  color: #3948d1;
}
.event-card-time {
  font-size: small;
  border-top: 1px solid #7d7d7d;
  color: #787878;
}


.event-card-address {
  color: #787878;
}

.event-card-date-sm {
  display: none;
  font-size: small;


}


.artwork-audio-popup {
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.67);
  height: 146px;
  width: 146px;
  position: absolute;
  top: 37%;
  left: 43%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 375px) {
  .artwork-audio-popup {
    top: 25%;
    left: 45%;
  }
}

@media (max-width: 767px) {
  .artwork-audio-popup {
    top: 25%;
    left: 30%;
  }

  .event-card-date {
    display: none;
  }

  .event-card-date-sm {
    display: block;
  }

  .event-card-time {
    display: none;
  }


  .event-card-address {
    color: #787878;
  }

}

/* iPhone 11
    1792x828px at 326ppi */
@media only screen and (device-width: 414px) and (device-height: 896px) {
  .h-94vh-map {
    height: 40vh;
  }
}

/* iPhone 11 Pro and iPhone 12 Mini
    2436x1125px at 458ppi */
@media only screen and (device-width: 375px) and (device-height: 812px) {
  .h-94vh-map {
    height: 40vh;
  }
}

/* iPhone 12 and iPhone 12 Pro
    2532x1170 pixels at 460ppi */
@media only screen and (device-width: 390px) and (device-height: 844px) {
  .h-94vh-map {
    height: 40vh;
  }
}

/*  iPhone 12 Pro Max
    2778x1284 pixels at 458ppi */
@media only screen and (device-width: 428px) and (device-height: 926px) {
  .h-94vh-map {
    height: 40vh;
  }
}

/*  Pixel 2 XL */
@media only screen and (device-width: 411px) and (device-height: 823px) {
  .h-94vh-map {
    height: 40vh;
  }
}

/*  iPhone 6/7/8 */
@media only screen and (device-width: 375px) and (device-height: 667px) {
  .h-94vh-map {
    height: 40vh;
  }
}

/*  iPhone 6/7/8 plus*/
@media only screen and (device-width: 414px) and (device-height: 736px) {
  .h-94vh-map {
    height: 40vh;
  }
}
@media (max-width: 767px) {
  .lg-hidden {
    display: none;
  }
  .sm-hidden {
    display: none;
  }
}
