.artist-card {
  border: 0.5px solid #c2dd15d8;
  color: white;
  margin-bottom: 25px;
  background-color: #000;
  padding: 12px;
  text-align: left;
  border-radius: 4px;
  display: flex;
}
.artist-card .artist-image {
  border-radius: 50%;
  width: 73px;
  height: 73px;
}

.outline-black-button {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  background-color: #000;

  color: #e0f752;
  padding: 12px 24px;
  border-radius: 4px;
}
.outline-black-button .user-add-icon {
  width: 24px;
}
.outline-black-button:hover {
  background-color: rgb(49, 49, 49);
}
.modal-styling .modal-content {
  padding: 8px;
  background-color: black;
  color: #fff;
}
.modal-styling .modal-header .close {
  color: white;
}
.modal {
  padding-left: 0px!important;
}
.congras-image {
  width: 150px;
  height: 150px;
  background-color: grey;
  border-radius: 50%;
  margin-bottom: 12px;
}

@media (max-width: 440px) {

  .myClass {
    margin-bottom: 1rem!important;
  }
} 

.form-event-input {
  border-radius: 5px;
  background-color: black;
  padding: 16px ;
  color: var(--white);
  width: 100%;
  height: 75%;
  border: 1px solid var(--gray100);
}
.form-event-input:focus {
  outline: 0;
  border-color: var(--lime);
  box-shadow: 0 0 0 0 var(--lime); 
}

.form-event-input-repeat {
  font-weight: 600;
}

.upload-artist-image-btn {
  width: 179px;
  height: 173px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  border:  2px solid var(--white);
  border-radius: 50%
}

@media (max-width: 460px){
  .addArtistbtn {
    width: 100%;
  }
}

/* @media (max-width: 440px) {

  .upload-artist-image-btn {
    width: 50vw;
  height: 21vh;
  border-radius: 50%
  }
} */

.popUpBtn {
  background-color: #e0f752;
  color: #001aff;
  font-style: normal;
  font-weight: 600;
  border-radius: 4px;
  text-align: center;
}
  
@media (max-width: 470px){
  .popUpBtn {
    font-size: 12px;
  }
  .outline-button{
    font-size: 12px;
  }
}

@media (max-width: 370px){
  .popUpBtn  {
    font-size: 10px;
  }
  .outline-button{
    font-size: 10px;
  }
}

.popUpBtn:hover {
  background-color: #d9f044;
}

.outline-button:hover {
  border: 0.5px solid #d9f044;
}

.outline-button {
  border: 0.5px solid #e0f752;
  background-color: #000;
  color: #e0f752;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
}

.info-text {
  font-family: sans-serif;
  font-size: 1rem;
}
@media (min-width: 470px){
  .info-text {
    font-family: sans-serif;
    font-size: 1.3rem;
  }
}