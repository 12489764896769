.stroke-black {
  filter: drop-shadow(0 0 10px #000) brightness(120%);
}
.cp {
  cursor: pointer;
  transform: scale(1);
}

.link-by-stilllife-svg {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.poweredby{

text-align: center;
margin-bottom: -25px;
font-size: large;
}
.arrow-color{
  fill: #e0f752;;
}
@media (max-width: 768px) {
  .stroke-black {
  }
}

.animate-event {
  transform-box: fill-box;
  transform-origin: center;
  animation-name: scaling;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
.hidden{
  display: none;
}

@keyframes scaling {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.12);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.12);
  }
  100% {
    transform: scale(1);
  }
}
