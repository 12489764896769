@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap);
html {
  position: relative;
  background-color: #121213;
  font-family: "Inter", sans-serif;

}


body::-webkit-scrollbar-track {
  background-color: transparent !important;
  
}
body{
  background-color: black !important;
}
      
body::-webkit-scrollbar {
  width: 0.5px !important;
  background-color: transparent!important;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}

@media (max-width: 768px) {
  body {
    overflow: scroll;
    background-color: black;
    
  }
}

iframe{
  display: none;
}
.ReactModal__Body--open{
  overflow: hidden;
}
.carousel-back-button {
  position: absolute;
  left: 10px;
  bottom: 50%;
}
.event-name-text {
  font-family: Karla;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.6px;
  color: #ffffff;
  margin: 25px auto;
}
.event-series-text {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #e0f752;
}
.back-btn-chevron {
  margin-top: 5px;
}
@media (max-width: 768px) {
  .back-btn-chevron {
    position: fixed;
    top: 20px;
  }
  .carousel-back-button {
    display: none;
  }
  .carousel-forward-button {
    display: none;
  }
  .mobile-back-btn-for-art-event {
    position: fixed;
    top: 6rem;
    background-color: #000000;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    padding: 5px;
    z-index: 20;
  }
}

.carousel-forward-button {
  position: absolute;
  left: 96%;
  bottom: 50%;
}
.sidebar-btn {
  position: fixed;
  top: 55%;
  z-index: 1;
  background-color: black;
  border-radius: 50%;
  width: 32px;
  padding-bottom: 3px;
  padding-top: 2px;
  padding-right: 3px;
  height: 32px;
}

.discover-title {
  margin: 60px 0px 0px 60px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 33px;
  color: #e0f752;
}

.discover-yes {
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
}

.discover-map-container {
  width: 395px;
  height: 376px;
  position: absolute;
  z-index: 10;
  top: 20%;
  left: 50%;
  background-color: black;
}

.discover-artwork-container {
  width: 395px;
  height: 376px;
  position: absolute;
  z-index: 10;
  top: 30%;
  left: 19%;
  background-color: black;
}
.art-event-map-and-list {
  width: 100%;
  overflow: auto;
}

.discover-container {
  width: 395px;
  height: 376px;
  background: #000000;
  justify-content: center;
}
.miami-art-week,
.chashama-gala,
.akumal-art-festival,
.link-by-stilllife {
  display: flex;
  overflow: auto;
  padding: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.link-by-NRVLD {
  width: 100%;
}

.artwork-container {
  padding: 15%;
  background: #e0f752;
  justify-content: center;
}

.hear-more {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  /* identical to box height, or 108% */
  letter-spacing: -0.42px;
  color: #000000;
}
.event-map-container {
  height: 100%;
  width: 100%;
}
.hear-more-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.6px;
  color: #000000;
  /* add 3 dot after 12 line */
  line-height: 20px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 12;
  -moz-line-clamp: 12;
  -ms-line-clamp: 12;
  line-clamp: 12;
  overflow: hidden;
}

.event-desc-2-line {
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.6px;
  color: #ffffff;
  /* add 3 dot after 12 line */
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}
.map-circle {
  background: #456bd9;
  border: 0.1875em solid #0f1c3f;
  border-radius: 50%;
  box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);
  height: 5em;
  width: 5em;
}
.event-desc-1-line {
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  opacity: 0.7;
  /* add 3 dot after 12 line */
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}
.discover-icon {
  text-align: end;
  margin: 15px;
}

.h-100vh {
  height: 100vh;
}

.bg-black {
  background: #000000;
}
.bg-light-black {
  background: #121212;
}

.bg-white {
  background: #ffffff;
}

.m-auto {
  margin: auto;
}

.w-100 {
  width: 100%;
}

.w-80 {
  width: 80%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-50-responsive {
  width: 50%;
}
.ReactModal__Overlay--after-open {
  z-index: 10000000 !important;
}
.w-10 {
  width: 10%;
}
.w-5 {
  width: 1%;
}

.w-37 {
  width: 37%;
}
@media (min-width: 768px) {
  .w-37 {
    width: 45%;
    max-width: 550px;
    min-width: 470px;
  }
  .chashama-event {
    height: 100vh;
  }
  .link-by-stilllife {
    height: 100vh;
  }
  .link-by-NRVLD {
    height: 100vh;
  }
}

.w-67 {
  width: 71%;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt--13 {
  margin-top: -13px;
}

.ml-30 {
  margin-left: 30px;
}
.slideInner___2mfX9 {
  position: static !important;
  display: flex;
  justify-content: center;
}
.m-t-30 {
  margin-top: -30px !important;
}

.m-t-20 {
  margin-top: -20px !important;
}

.mt--21 {
  margin-top: 10px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-27 {
  margin-right: 27px;
}

.mt--10 {
  margin-top: -10px;
}

.font-weight-bold {
  font-weight: bold;
}
.dot-3-line-1 {
  /* add 3 dot after 2 line */
  line-height: 20px;
  /* word-break: break-all; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -ms-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
}
.dot-3 {
  /* add 3 dot after 2 line */
  line-height: 20px;
  /* word-break: break-all; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}

.margin-10 {
  margin: 10px;
}

.ta-end {
  text-align: end;
}

.ta-start {
  text-align: start;
}

.ta-center {
  text-align: center;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.f-size-12 {
  font-size: 12px !important;
}

.pr-29 {
  padding-right: 29px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-15 {
  padding-right: 15px;
}

.pad-15 {
  padding: 15px;
}

.mt-8 {
  margin-top: -8px;
}

.margin-5 {
  margin: 5px;
}

.postion-relative {
  position: relative;
}

.pad-all {
  padding: 2px 10px 0px 10px;
}

.display-box {
  display: -webkit-box;
}

.display-block {
  display: block;
}

.display-none-all {
  display: none;
}

.display-none {
  display: none;
}

.display-initial {
  display: -webkit-box;
}

.display-flex {
  display: flex;
}

.flex-direction-row {
  flex-direction: row;
}

.display-direction-row {
  display: flex;
  flex-direction: row;
}

.display-direction-row_main {
  display: flex;
  flex-direction: row;
}

.display-direction-column {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .display-rows {
    display: flex;
    flex-direction: column;
    padding-top: 10rem;
  }
}

.overflow-auto {
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.overflow-auto::-webkit-scrollbar {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}
.overflow-x-hidden {
  overflow-y: hidden;
}

.h-100vh-map {
  height: 100vh;
}

.overflow-x-scroll {
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.overflow-x-scroll::-webkit-scrollbar {
  display: none;
}
.overflow-scroll {
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.overflow-scroll::-webkit-scrollbar {
  display: none;
}
.color-black {
  color: black !important;
}

.h-60 {
  height: auto;
}
@media only screen and (min-width: 768px) {
  .h-60 {
    height: 105vh;
  }
}

.content-between {
  justify-content: space-between;
}

.w-30 {
  width: 30%;
}
.w-20 {
  width: 20%;
}

.font-weight-bold {
  font-weight: bold;
}

.horizontal-div {
  width: 57px;
  height: 4px;
  background: #e0f752;
  border-radius: 20px;
}

.show-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.show-scroll::-webkit-scrollbar {
  height: 10px !important;
  background-color: transparent;
}

.show-scroll::-webkit-scrollbar-thumb {
  background-color: #e0f752;
  border-radius: 50px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.row1 {
  display: flex;
}

.artwork-image-container {
  max-height: 90vh;
  height: 100%;
}

.no-events-show {
  position: absolute;

  margin-left: 33px;
}
.card-list-color-and-height {
  background-color: rgba(0, 0, 0);
}

@media (max-width: 767px) {
  .miami-art-week {
    width: 150%;
  }

  .display-rows {
    display: -webkit-box;
    overflow-y: hidden;
  }
  .card-sm {
    width: 80%;
  }
  .event-map-container {
    height: 60vh;
  }
  .chashama-gala {
    width: 130%;

    padding-top: 5vh;
  }
  .link-by-stilllife {
    width: 130%;
    padding: 0px;
  }
  .link-by-NRVLD {
    width: 100%;
    height: 60vh;
  }
  .akumal-art-festival {
    width: 200%;
  }
  .row1 {
    display: inline-grid;
  }
  .no-events-show {
    position: inherit;
    background-color: #000000;
    width: 100%;
    padding-bottom: 25px;
    margin-top: 0px;
    margin-left: 0px;
  }

  .artwork-image-container {
    width: 100%;
    height: 100%;
  }

  .display-direction-row_main {
    flex-direction: column-reverse !important;
  }
  .xs-100 {
    width: 100% !important;
  }
  .discover-map-container {
    width: 0px !important;
    top: 30% !important;
    left: 4% !important;
    background-color: #212121 !important;
  }
  .discover-container {
    padding-top: 10px;
    width: 330px;
  }
  .h-100vh {
    height: 100%;
  }
  .h-100vh-map {
    height: 100vh;
    position: relative;
    -webkit-animation-name: up;
            animation-name: up;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }

  .h-94vh-map {
    height: 87vh;
    position: relative;
    -webkit-animation-name: bottom;
            animation-name: bottom;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }

  .mt--21 {
    margin-top: -21px !important;
  }

  .card-list-color-and-height {
    bottom: 0px;
    background: none;
    position: fixed;
    z-index: 1;
  }
  .map-and-list-height .card-list-color-and-height {
    bottom: 250px;
    background: none;
    position: relative;
    z-index: 1;
  }
  /*  iPhone 6/7/8 */

  .h-10-card-list {
    height: 10vh;
  }

  .w-50-responsive {
    width: 100%;
  }
  .artwork-container {
    width: 0px !important;
    height: 0px !important;
  }
  .display-none {
    display: -webkit-box;
  }
  .display-initial {
    display: none;
  }
  .lg-show {
    display: none !important;
  }
  .xs-show {
    display: -webkit-inline-box;
  }
  .ml-30 {
    margin-left: 0px;
  }
}

@media (min-width: 767px) {
  .xs-show {
    display: none !important;
  }
  .lg-show {
    display: block;
  }
}

.change-image {
  font-size: 1.25rem;
  font-family: "New-York-Medium", sans-serif;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (max-width: 500px) {
  .loader-mobile {
    display: flex;
    align-items: center;
  }
}

.sidebar-fixed-top {
  position: fixed;
  top: 4rem;
  padding-top: 2rem;
  background-color: black;
  z-index: 1;
}

@media (max-width: 750px) {
  .sidebar-fixed-top {
    background-color: transparent !important;
  }
}

@media (min-width: 750px) {
  .z-3 {
    z-index: 3;
  }
}

.art-event-sidebar-fixed-top {
  position: fixed;
  top: 2.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #000000;
  width: 30%;
  max-width: 550px;
  min-width: 470px;
  z-index: 10;
}

@media (max-width: 750px) {
  .art-event-sidebar-fixed-top {
    background-color: transparent !important;
  }
}

.navbar-user-icon {
  color: #ababab;
  background-color: #ababab66;
  border-radius: 50%;
  width: 36px;
  padding: 4px;
}

.notification-group {
  position: relative;
}
.notification-dot {
  position: absolute;
  right: 2px;
  top: 2px;
  background-color: red;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.navbar-user-icon:hover {
  color: white;
}

.navbar-logout-icon {
  width: 20px;
}
.navbar-dropdown-custom .dropdown-toggle::after {
  content: none;
}

@media (max-width: 460px) {
  .custom-navbar-for-create-event-screen .bg-dark {
    background-color: #000 !important;
  box-shadow: 0px 0px 4px 1px var(--lime);
  }
}

@media (min-width : 460px){
  .custom-navbar-for-create-event-screen .bg-dark {
    background-color: #000 !important;
  }
}

.custom-navbar .bg-dark {
  background-color: #000 !important;
}

.custom-navbar .dropdown-menu {
  right: 0 !important;
  left: unset !important;
}

.nav-height {
  padding: 1rem !important;
}

.view-ar-link {
  background-color: #333;
  padding: 12px;
  border-radius: 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 20px;
}

.homepage-container {
  background-color: var(--black100);
  height: 100%;
  text-align: left;
  padding-left: 4%;
  padding-right: 4%;
}

.social-media-btn-container {
  height: 50px;
  width: 50px;
  padding: 10px;
  border-radius: 50%;
  background-color: var(--white);
}

.homepage-heading {
  text-align: start;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 16px;
  line-height: 28px;
  letter-spacing: -0.36px;
}

@media (min-width: 768px) {
  .homepage-container {
    padding-left: 16%;
    padding-right: 16%;
  }
}

.lime {
  color: var(--lime);
}

.white {
  color: var(--white);
}

.city-image {
  opacity: 0.56;
}

.city-names {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;
  letter-spacing: -0.36px;
  color: var(--black);
}

.city-list-container {
  /* margin-top: 21px; */
  padding-top: 12rem;
  /* border-top: 1px solid #ffffff50; */
}

@media (max-width: 750px) {
  .city-list-container {
    padding-top: 200px;
  }
}

.no-of-cities {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: rgb(255, 255, 255, 0.5);
}

.event-image {
  border-radius: 9px 9px 0px 0px;
}

.br--9 {
  border-radius: 0px 0px 9px 9px;
}

.info-window-name {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  color: var(--black);
}

.event-cards-desktop {
  max-height: 185px;
  height: 100%;
  cursor: pointer;
  background-color: var(--white);
  border-radius: 12px;
  padding: 10px 10px;
  display: flex;
  max-width: 600px;
}

.event-cards-desktop img {
  border-radius: 12px;
  min-height: 165px;
  max-height: 165px;
  min-width: 160px;
  max-width: 160px;
  object-fit: cover;
  background-color: var(--red100);
}

@media (max-width: 600px) {
}

.br--18 {
  border-radius: 18px;
}

.br--12 {
  border-radius: 12px;
}

.info-window-date {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--black);
  text-align: left;
  opacity: 0.5;
}

/* .city-names:hover {
  font-family: Karla;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.36px;
  color: #e0f752;
  opacity: 1;
} */
/* .city-names:active {
  font-family: Karla;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.36px;
  color: #e0f752;
  opacity: 1;
} */
.city-detail-container {
  padding: 0% !important;
  height: 100px;
  width: 300px;
}

.feature-detail-container {
  padding: 0% !important;
  height: 100px;
  width: 500px;
}

.city-background {
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.15);
  align-items: center;
}

.edit-profile-btn {
  opacity: 0.5;
  /* White */
  font-weight: 600;
  font-size: 20px;
  line-height: 16.41px;
  border-radius: 19px;
  padding: 8px 22px;
  height: 32px;
  border: 1px solid var(--white);
  background: transparent;
  color: var(--white);
}

/* Radio button Css */
.tabs {
  list-style-type: none;
  display: flex;
  width: 100%;
  padding: 0;
}

@media (max-width: 768px) {
  .tabs {
    overflow-x: scroll;
  }

  .event-cards-desktop {
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    max-height: 350px;
    /* TODO: AUTO Height adjust */
    height: 100%;
    min-height: 300px;
  }

  .event-cards-desktop img {
    border-radius: 12px;
    min-width: none;
    max-height: none;
    min-width: none;
    max-width: none;
    object-fit: cover;
  }
}

.tabs input[type="radio"] {
  visibility: hidden;
  height: 0;
  width: 0;
}

.tabs label {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  border-radius: 19px;
  padding: 8px 22px;
  /* height: 32px; */
  width: -webkit-max-content;
  width: max-content;
  background-color: rgb(255, 255, 255, 0.32);
  color: var(--white);
  letter-spacing: -0.36px;
  white-space: nowrap;
}

.tabs input[type="radio"]:checked + label {
  color: var(--blue);
  background-color: var(--lime);
}

.forward-arrow {
  width: 24px;
  margin-left: 4px;
  margin-top: 1px;
}

.horizontal-ruler {
  width: 100%;
  border-bottom: 1px solid var(--black);
}

@media(max-width:550px) {
  .main {
    text-align: left;
    background-color: black;
    padding-bottom: 3vh;
    margin-top: 2rem;
  }
}

@media (min-width: 551px) {
  .main {
    box-shadow: 0px 0px 10px -4px #333;
    text-align: left;
    margin-bottom: 3vh;
    background-color: black;
    margin-top: 7rem;
    padding-bottom: 5vh;
    border-radius: 10px;
  }
}

.event {
  margin-top: 5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--paleBlue);
}

input:-internal-autofill-selected {
  -webkit-appearance: menulist-button;
          appearance: menulist-button;
  background-image: none !important;
  background-color: red !important;
  color: red !important;
}

.form {
  border-radius: 5px;
  background-color: transparent;
  padding: 16px;
  color: var(--white);
  width: 100%;
  height: 51px;
  border: 1px solid var(--gray100);
  cursor: text;
}

.form:focus {
  outline: 0;
  border-color: var(--lime);
  box-shadow: 0 0 0 0 var(--lime);
}

.form-repeat {
  font-weight: 600;
}

.date {
  border-radius: 3px;
  width: 100%;
  height: 50px;
  outline: none;
  border: 0px;
  cursor: auto;
  background-color: transparent;
  color: white;
}

.upload-img-btn:hover {
  border-color: var(--lime);
}

.upload-img-btn:active {
  border-color: var(--lime);
}

.upload-img-btn {
  width: 58px;
  height: 58px;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  border: 2px solid var(--white);
  border-radius: 8px;
  object-fit: contain;
}

.delete-icon {
  width: 19px;
  height: 19px;
  cursor: pointer;
  color: var(--white);
  position: absolute;
  margin-top: -8px;
  margin-left: 41px;
  background-color: red;
  border-radius: 15px;
}

/* .input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: .75rem;
  position: relative;
  width: 100%;
  cursor: pointer;
} */

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  /* background-position: right;
    background-size: auto; */
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  /* filter: invert(1); */
}

/* input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  filter: invert(1);
} */

@media (min-width: 460px) {
  .image-previewer {
    height: 280px;
    max-width: 320px;
    width: 100%;
    border: 2px dashed var(--white);
    padding: 10px;
    display: flex;
    justify-content: center;
    background-color: transparent;
    border-radius: 8px;
  }
}

@media (max-width: 460px) {
  .image-previewer {
    height: 280px;
    width: 100%;
    border: 2px dashed var(--white);
    padding: 10px;
    display: flex;
    justify-content: center;
    background-color: transparent;
    border-radius: 8px;
  }
}

.image-previewer img {
  object-fit: contain;
  border-radius: 8px;
}

.text_small {
  font-size: 13px;
}

.invalid-text {
  color: var(--pink-red);
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

/* .sm_btn {
  width: 150px;
} */

@media (max-width: 460px) {
  .sm_btn {
    width: 100%;
  }
}

::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(1);
          filter: invert(1);
  cursor: pointer;
}

.recaptcha div div div iframe {
  display: block !important;
}

html body div div iframe[title="recaptcha challenge expires in two minutes"] {
  display: block !important;
}

@media (min-width: 460px) {
  .width-md {
    width: 86%;
  }
}

.cities-fixed-top {
  position: fixed;
  top: 4.5rem;
  z-index: 100;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  width: 100%;
  background-color: #121213;
}
.pointer {
  cursor: pointer;
}
.bg-white {
  background-color: white;
}

.scroll-col {
  flex: 1 1;
  background: #343a40;
  overflow-y: scroll;
}
.button {
  border: 0;
  margin: 0;
  padding: 0;
}

.home-page-card-button {
  display: flex;
  justify-content: center;
  border: 0;
  margin: 0;
  padding: 0 !important;
}

.home-page-cards-container {
  background: #000;
  padding: 3rem;
  height: 100vh;
}
.card-component-container {
  background: #121213;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 5px;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.small_container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}
.dfc {
  display: flex;
  flex-direction: column;
}

.card {
  overflow-y: scroll;
  max-height: 80vh;
}

.title {
  text-align: start;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 54px;
  color: #000000;
}

.desc {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.artists-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.42px;
  color: #000000;
}

.card-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #f8f6f8;
}

.card-address {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.42px;
  color: #f8f6f8;
}

.text-white {
  color: white;
}
.bg-color {
  background-color: #000000 !important;
}
.bg-grey {
  background-color: #121213;
}

.bg-grey-600 {
  background-color: #191919;
}
@media (max-width: 768px) {
  .make-us-visible-img {
    height: 360px;
  }
}

.change-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.change-scroll::-webkit-scrollbar {
  width: 32px !important;
  background-color: transparent;
}

.change-scroll::-webkit-scrollbar-thumb {
  background-color: #e0f752;
  border: 14px solid #000;
  background-clip: padding-box;
  border-radius: 50px;
}

.full-image_container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 17;
}

@media (max-width: 850px) {
  .card-component-container {
    width: 100%;
  }
  .home-page-cards-container {
    height: -webkit-max-content;
    height: max-content;
  }
}

.set-margin-top-for-cities-page {
  margin-top: 2.5rem !important;
}

@media (min-width: 750px) {
  .set-margin-top-for-cities-page {
    margin-top: 3rem !important;
  }
}

.sideBar-container {
  overflow: hidden;

  border-radius: 12px;
  background-color: black;
  padding: 1.5rem;
}

.sideBar-card {
  max-height: 185px;
  height: 100%;
  cursor: pointer;
  background-color: var(--white);
  border-radius: 12px;
  padding: 10px 10px;
  display: flex;
  grid-column-gap: 3%;
  -webkit-column-gap: 3%;
          column-gap: 3%;
  max-width: 600px;
}
.sideBar-card img {
  border-radius: 12px;
  min-height: 165px;
  max-height: 165px;
  min-width: 160px;
  max-width: 160px;
  object-fit: cover;
  background-color: var(--red100);
}

@media (max-width: 600px) {
  .sideBar-card {
    flex-wrap: wrap;
    max-height: 350px;
    height: 100%;
  }
  .sideBar-card img {
    flex: 1 1;
  }
}

.mt-9rem {
  margin-top: 9rem;
}

@media (max-width: 768px) {
  .mt-9rem {
    margin-top: 11vh;
  }
}

.mt-4rem {
  margin-top: 4rem;
}

@media (max-width: 768px) {
  .mt-4rem {
    margin-top: 4vh;
  }
}

.artist-card {
  border: 0.5px solid #c2dd15d8;
  color: white;
  margin-bottom: 25px;
  background-color: #000;
  padding: 12px;
  text-align: left;
  border-radius: 4px;
  display: flex;
}
.artist-card .artist-image {
  border-radius: 50%;
  width: 73px;
  height: 73px;
}

.outline-black-button {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  background-color: #000;

  color: #e0f752;
  padding: 12px 24px;
  border-radius: 4px;
}
.outline-black-button .user-add-icon {
  width: 24px;
}
.outline-black-button:hover {
  background-color: rgb(49, 49, 49);
}
.modal-styling .modal-content {
  padding: 8px;
  background-color: black;
  color: #fff;
}
.modal-styling .modal-header .close {
  color: white;
}
.modal {
  padding-left: 0px!important;
}
.congras-image {
  width: 150px;
  height: 150px;
  background-color: grey;
  border-radius: 50%;
  margin-bottom: 12px;
}

@media (max-width: 440px) {

  .myClass {
    margin-bottom: 1rem!important;
  }
} 

.form-event-input {
  border-radius: 5px;
  background-color: black;
  padding: 16px ;
  color: var(--white);
  width: 100%;
  height: 75%;
  border: 1px solid var(--gray100);
}
.form-event-input:focus {
  outline: 0;
  border-color: var(--lime);
  box-shadow: 0 0 0 0 var(--lime); 
}

.form-event-input-repeat {
  font-weight: 600;
}

.upload-artist-image-btn {
  width: 179px;
  height: 173px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  border:  2px solid var(--white);
  border-radius: 50%
}

@media (max-width: 460px){
  .addArtistbtn {
    width: 100%;
  }
}

/* @media (max-width: 440px) {

  .upload-artist-image-btn {
    width: 50vw;
  height: 21vh;
  border-radius: 50%
  }
} */

.popUpBtn {
  background-color: #e0f752;
  color: #001aff;
  font-style: normal;
  font-weight: 600;
  border-radius: 4px;
  text-align: center;
}
  
@media (max-width: 470px){
  .popUpBtn {
    font-size: 12px;
  }
  .outline-button{
    font-size: 12px;
  }
}

@media (max-width: 370px){
  .popUpBtn  {
    font-size: 10px;
  }
  .outline-button{
    font-size: 10px;
  }
}

.popUpBtn:hover {
  background-color: #d9f044;
}

.outline-button:hover {
  border: 0.5px solid #d9f044;
}

.outline-button {
  border: 0.5px solid #e0f752;
  background-color: #000;
  color: #e0f752;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
}

.info-text {
  font-family: sans-serif;
  font-size: 1rem;
}
@media (min-width: 470px){
  .info-text {
    font-family: sans-serif;
    font-size: 1.3rem;
  }
}
.event-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 25px;
  color: #ffffff;
}

.event-desc-heading {
  font-style: normal;
  font-size: 20px;
  color: #ffffff;
}

.lime-btn-with-blue-text {
  background-color: #e0f752;
  color: #001aff;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  border-radius: 4px;
  text-align: center;
  padding: 12px 20px;
}

.lime-btn-with-blue-text:hover {
  background-color: #d9f044;
}


.event-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.32px;
  color: #ffffff;
  opacity: 0.5;
}

.login-btn {
  color: #ffffff;
  background: none;
  border: 1px solid #001aff;
  border-radius: 4px;
  padding: 8px 24px;
}

.login-btn:hover {
  background-color: #001aff;
  color: #fff;
}

@media (max-width: 380px) {
  .create-event-btn {
    margin: 0rem!important;
    padding: 0.5rem 0.5rem!important;
  }
}

@media (max-width: 768px) {
  .create-event-btn {
    margin: 0rem!important;
  }
}


.create-event-btn {
  font-size: 18px;
  color: #e0f752;
  background: none;
  border: 1px solid #c4c4c494;
  border-radius: 6px;
  margin: 0px 1rem;
  padding: 0.5rem 1.25rem;
}

.create-event-btn:hover {
  background-color: #e0f752;
  color: #001aff;
}

.artist-name {
  font-size: 17px;
  line-height: 20px;
  color: #fff;
}

.exhibition-name {
  font-style: normal;
  line-height: 25px;
  font-weight: bold;
  font-size: 20px;
}

.event-learn-more {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}

.hide-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.hide-scroll::-webkit-scrollbar {
  width: 3px !important;
  background-color: transparent;
}

.hide-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.artwork-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.12px;
  color: #ffffff;
}

.artwork-name {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
}

.view-website {
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: -0.12px;
  color: #FB5959;
  ;

}

.artists-location {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  opacity: 0.5;
  letter-spacing: -0.11px;
  color: #ffffff;
  margin-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: start;
}

.location-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.12px;
  color: #ffffff;
}

.react-modal-sheet-container {
  /* custom styles */
  background-color: #292929 !important;
}

.react-modal-sheet-drag-indicator {
  /* custom styles */
  background-color: #e0f752 !important;
  width: 30px !important;
}

.react-modal-sheet-backdrop {
  /* custom styles */
  overflow: hidden !important;
}

.react-modal-sheet-header {
  height: 70px !important;
  background-color: #000000;
}

.location {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  opacity: 0.8;
  color: #ffffff;
}

.event-date {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.32px;
  color: #ffffff;
  opacity: 0.5;
}

.button-rsvp {
  float: right;
  height: 30px;
  width: 113px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.image-container {
  height: 280px;
  width: 300px;
}

.image-container-2 {
  height: 165px;
  width: 100%;
  cursor: pointer;
  background-repeat: no-repeat;
}

.image-container-3 {
  height: 188px;
  width: 191px;
}

.object-fit-image {
  object-fit: cover;
}

.artist-img-container {
  height: 81px;
  width: 81px;
  cursor: pointer;
  border-radius: 50%;
}

.artwork-img-container {
  height: 100px;
  width: 100px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 12px;
}

.artwork-audio {
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.67);
  height: 55px;
  width: 55px;
  position: absolute;
  top: 23%;
  left: 23%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.event-card-date {
  color: #3948d1;
}
.event-card-artist {
  color: #3948d1;
}
.event-card-time {
  font-size: small;
  border-top: 1px solid #7d7d7d;
  color: #787878;
}


.event-card-address {
  color: #787878;
}

.event-card-date-sm {
  display: none;
  font-size: small;


}


.artwork-audio-popup {
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.67);
  height: 146px;
  width: 146px;
  position: absolute;
  top: 37%;
  left: 43%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 375px) {
  .artwork-audio-popup {
    top: 25%;
    left: 45%;
  }
}

@media (max-width: 767px) {
  .artwork-audio-popup {
    top: 25%;
    left: 30%;
  }

  .event-card-date {
    display: none;
  }

  .event-card-date-sm {
    display: block;
  }

  .event-card-time {
    display: none;
  }


  .event-card-address {
    color: #787878;
  }

}

/* iPhone 11
    1792x828px at 326ppi */
@media only screen and (device-width: 414px) and (device-height: 896px) {
  .h-94vh-map {
    height: 40vh;
  }
}

/* iPhone 11 Pro and iPhone 12 Mini
    2436x1125px at 458ppi */
@media only screen and (device-width: 375px) and (device-height: 812px) {
  .h-94vh-map {
    height: 40vh;
  }
}

/* iPhone 12 and iPhone 12 Pro
    2532x1170 pixels at 460ppi */
@media only screen and (device-width: 390px) and (device-height: 844px) {
  .h-94vh-map {
    height: 40vh;
  }
}

/*  iPhone 12 Pro Max
    2778x1284 pixels at 458ppi */
@media only screen and (device-width: 428px) and (device-height: 926px) {
  .h-94vh-map {
    height: 40vh;
  }
}

/*  Pixel 2 XL */
@media only screen and (device-width: 411px) and (device-height: 823px) {
  .h-94vh-map {
    height: 40vh;
  }
}

/*  iPhone 6/7/8 */
@media only screen and (device-width: 375px) and (device-height: 667px) {
  .h-94vh-map {
    height: 40vh;
  }
}

/*  iPhone 6/7/8 plus*/
@media only screen and (device-width: 414px) and (device-height: 736px) {
  .h-94vh-map {
    height: 40vh;
  }
}
@media (max-width: 767px) {
  .lg-hidden {
    display: none;
  }
  .sm-hidden {
    display: none;
  }
}

.bottom-container {
  position: absolute;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  color: aliceblue;
  scroll-snap-type: y mandatory;
  background-color: transparent;
}
.bottom-sheet-top {
  padding: 20px;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  scroll-snap-align: end;
}
.bottom-sheet {
  box-shadow: 0px -1px 10px #606060;
  padding: 12px;
  width: 100vw;
  height: 87vh;
  background-color: black;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  scroll-snap-align: center;
}
.bg-lime {
  margin: 6px;
  background: #e0f752;
  width: 60px;
  height: 2px;
  border-radius: 20px;
}
.top-sheet{
display: flex;
flex-direction: column;
align-items: center;

}
.h-20 {
  height: 30px;
}
.scroll {
  overflow: scroll;
}
.scroll::-webkit-scrollbar {
  display: none;
}
.sheet-content-container {
  width: 100%;
  height: 75vh;
  background-color: black;
}
@media (min-width: 767px) {
    .bottom-container{
        display: none;
    }
}
.swipe-down{
    margin-bottom: 10px;
    font-size: 14px;
    color: black;
    -webkit-animation-name: swipe;
            animation-name: swipe;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: 2;
            animation-iteration-count: 2;
}

@-webkit-keyframes swipe{
    0% {
      color: black;
    }
    25% {
        color: #808080;
    }
    50% {
        color: black;
    }
    75% {
        color: #808080;
    }
    100% {
        color: black;
    }
  }

@keyframes swipe{
    0% {
      color: black;
    }
    25% {
        color: #808080;
    }
    50% {
        color: black;
    }
    75% {
        color: #808080;
    }
    100% {
        color: black;
    }
  }

  .react-modal-sheet-content{
    background: black;
  }
  
.h-screen {
  height: 100vh;
}

.max-h-screen {
  max-height: 100vh;
}

.h-90vh {
  height: 90vh;
}

.overflow-y-scroll {
  overflow-y: scroll;
}
.br {
  border-right: 2px solid #e0f752;
}

.overflow-y-scroll::-webkit-scrollbar {
  display: none;
}

.z-20 {
  z-index: 20;
}
/* 
iframe:last-child {
  display: none;
} */
.lg-hidden {
  display: none;
}
@media (max-width: 767px) {
  .lg-hidden {
    display: block;
  }
  .sm-hidden {
    display: none;
  }
}
.bg-parrot {
  background-color: #e0f752;
}
.text-parrot {
  color: #e0f752;
}
iframe {
  display: none;
}

.gm-style-iw-c{
    padding-bottom: 10px !important;
    padding-right: 10px !important;

}
.gm-style-iw-d{
    overflow: hidden !important;
}

.gm-ui-hover-effect:focus{
    outline: none ;
}

.mint-btn{
  height:52px;
  width: 180px;
  font-weight: 500;
  padding: auto;
  border:none;
  border-radius: 4px;
  background-color: #e0f752;
}
.toast-header{
  justify-content: space-between;
}

.font-color{
  color: rgb(255,255,255,0.7);

}
.artistRoom{
    fill:#E3E4E4;
    cursor:pointer;

}
.artistRoomClicked{
    fill:#e0f752;
}
.stroke-black {
  -webkit-filter: drop-shadow(0 0 10px #000) brightness(120%);
          filter: drop-shadow(0 0 10px #000) brightness(120%);
}
.cp {
  cursor: pointer;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.link-by-stilllife-svg {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.poweredby{

text-align: center;
margin-bottom: -25px;
font-size: large;
}
.arrow-color{
  fill: #e0f752;;
}
@media (max-width: 768px) {
  .stroke-black {
  }
}

.animate-event {
  transform-box: fill-box;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation-name: scaling;
          animation-name: scaling;
  -webkit-animation-duration: 4s;
          animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.hidden{
  display: none;
}

@-webkit-keyframes scaling {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.12);
            transform: scale(1.12);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.12);
            transform: scale(1.12);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scaling {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.12);
            transform: scale(1.12);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.12);
            transform: scale(1.12);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.mainNRVLD {
    display: flex;
    justify-content: center;
    background-color: #787878;
}

.NRVLDImg {
    height: 100vh;
}

@media (max-width: 768px) {
    .NRVLDImg {
        height: 60vh;
        width: 100%;
    }
}


:root {
    --blue: #001aff;
    --white: #ffffff;
    --black100: #121213;
    --gray700: #ffffff50;
    --gray100:#c4c4c494;
    --red100: #e9e9e9;
    --lime: #e0f752;
    --black: #000;
    --red: #FF0000;
    --pink-red: #dc3545;
    --paleBlue: #dee2e6;
  }

@font-face {
  font-family: 'SF-Compact-Black';
  src: url(/static/media/SF-Compact-Display-Black.d20ed3bc.woff2);
}

@font-face {
  font-family: 'SF-Compact-Medium';
  src: url(/static/media/SF-Compact-Display-Medium.f241981d.woff2);
}
@font-face {
  font-family: 'SF-Compact-Regular';
  src: url(/static/media/SF-Compact-Display-Regular.c0959758.woff2);
}
@font-face {
  font-family: 'SF-Compact-Light';
  src: url(/static/media/SF-Compact-Display-Light.7a939d10.woff2);
}
@font-face {
  font-family: 'SF-Compact-Semibold';
  src: url(/static/media/SF-Compact-Display-Semibold.37359d0b.woff2);
}

@font-face {
  font-family: 'SF-Compact-Bold';
  src: url(/static/media/SF-Compact-Display-Bold.2fc7ac3c.woff2);
}
@font-face {
  font-family: 'New-York-Medium';
  src: url(/static/media/NewYorkExtraLargeMedium.2cd1e08d.otf);
}
@font-face {
  font-family: 'New-York-Extra-Large';
  src: url(/static/media/NewYorkExtraLargeBlack.45868346.woff2);
}
@font-face {
  font-family: 'New-York-Regular';
  src: url(/static/media/NewYorkExtraLargeRegular.f4ffdb41.otf);
}
@font-face {
  font-family: 'New-York-Semibold';
  src: url(/static/media/NewYorkExtraLargeSemibold.f34c58c9.otf);
}
li,
ul {
  font-family: 'SF-Compact-Regular', 'sans-serif';
}
body,
p,
label {
  font-family: 'SF-Compact-Medium', 'sans-serif';
  font-size: 16px;
}
em,
strong,
small {
  font-family: 'SF-Compact-Medium', 'sans-serif';
  font-size: 16px;
  opacity: 0.5;
  letter-spacing: 0.5px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'New-York-Medium', sans-serif;
}

/* NEW YORK FONT */
/* CDN LINK --- https://www.cdnfonts.com/new-york-extra-large.font */
.NY_Font--xl {
  font-family: 'New-York-Extra-Large', sans-serif;
}

.NY_Font--sb {
  font-family: 'New-York-Semibold', sans-serif;
}
.NY_Font--md {
  font-family: 'New-York-Medium', sans-serif;
}
.NY_Font--rg {
  font-family: 'New-York-Regular', sans-serif;
}

/* SF COMPACT DISPLAY FONT */
/* FONT LINK --- https://developers.apple.com */
.SCD_Font--black {
  font-family: 'SF-Compact-Black', 'sans-serif';
}

.SCD_Font--regular {
  font-family: 'SF-Compact-Regular', 'sans-serif';
}
.SCD_Font--semibold {
  font-family: 'SF-Compact-Semibold', 'sans-serif';
}
.SCD_Font--medium {
  font-family: 'SF-Compact-Medium', 'sans-serif';
}
.SCD_Font--bold {
  font-family: 'SF-Compact-Bold', 'sans-serif';
}

