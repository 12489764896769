:root {
    --blue: #001aff;
    --white: #ffffff;
    --black100: #121213;
    --gray700: #ffffff50;
    --gray100:#c4c4c494;
    --red100: #e9e9e9;
    --lime: #e0f752;
    --black: #000;
    --red: #FF0000;
    --pink-red: #dc3545;
    --paleBlue: #dee2e6;
  }
